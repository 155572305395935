@font-face {
  font-family: 'Tusker Grotesk 5700';
  src: url('../src/assets/fonts/TuskerGrotesk-5700Bold.woff2') format('woff2'),
    url('../src/assets/fonts/TuskerGrotesk-5700Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Libre Franklin';
  src: url('../src/assets/fonts/LibreFranklin-SemiBold.woff2') format('woff2'),
    url('../src/assets/fonts/LibreFranklin-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Libre Franklin';
  src: url('../src/assets/fonts/LibreFranklin-Medium.woff2') format('woff2'),
    url('../src/assets/fonts/LibreFranklin-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Libre Franklin';
  src: url('../src/assets/fonts/LibreFranklin-Regular.woff2') format('woff2'),
    url('../src/assets/fonts/LibreFranklin-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CSTM Xprmntl 02';
  src: url('../src/assets/fonts/CSTMXprmntl02-It.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/fonts/CSTMXprmntl02-It.woff2') format('woff2'),
    url('../src/assets/fonts/CSTMXprmntl02-It.woff') format('woff'),
    url('../src/assets/fonts/CSTMXprmntl02-It.ttf') format('truetype'),
    url('../src/assets/fonts/CSTMXprmntl02-It.svg#CSTMXprmntl02-It') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


body {
  margin: 0;
  font-family: 'Libre Franklin';
  font-size: 15px;
  color: #000;
}

p {
  font-size: 12px;
  color: #000;
  line-height: initial;
  text-transform: uppercase;

}

p a {
  color: #000 !important;
  text-decoration: none !important;
}

img {
  max-width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:hover {
  color: #0cafff;
}

#mySidenav {
  background-color: #101010;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.MenuIcon {
  background-color: transparent !important;
}

.MenuIcon img {
  width: 50px;
}

.SideUls {
  padding: 20px 40px;
  padding-top: 120px;
}

.socialUls {
  padding: 20px 20px;
}

.socialUls p {
  font-size: 10px;
  color: #fff;
  text-align: center;
  white-space: nowrap;
}

.socialUls ul {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin-bottom: 3px;
}

.socialUls li {
  padding: 0 7px;
}

.socialUls a {
  color: #ffffff;
  font-size: 12px;
  text-decoration: none;
}

.socialUls a:hover {
  color: #0cafff;
}

.Caption h2 a {
  color: #fff;
  text-decoration: none;
}

.mainHome .MenuIcon img {
  filter: invert(10000%);
  /* position: fixed; */
}
.mainHome.work .MenuIcon img {
    filter: none;
}
.Close {
  position: absolute !important;
  top: 10px;
  right: 10px;
  transform: rotate(90deg);
  line-height: 1;
  background-color: transparent !important;
}

.Close span {
  font-size: 60px;
  font-weight: bold;
  font-family: 'Tusker Grotesk 5700';
  color: #fff;
}

.Close span~span {
  display: none;
}

.menuOption {
  font-size: 50px;
  font-weight: bold;
  font-family: 'Tusker Grotesk 5700';
  color: #fff;
  display: block;
  line-height: 1.2 !important;
  padding: 0 !important;
  text-decoration: none;
  text-transform: uppercase;
  transition: all ease 0.2s;
}

.menuOption:hover {
  color: #0cafff;
}

.heading {
  font-size: 100px;
  font-weight: bold;
  font-family: 'Tusker Grotesk 5700';
  color: #000;
  margin: 0;
  text-transform: uppercase;
  line-height: 1;
}

/* 
.headingAbout {
  padding-left: 130px;
} */

.AboutVideos {
  position: relative;
}

.AboutVideos video {
  height: 600px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.whyCollab {
  position: relative;
  z-index: 1;
  background-color: #fff;
  height: 22vw;
}

.btn-black {
  background-color: #000;
  color: #0cafff;
  padding: 18px 65px;
  border-radius: 0;
  border: 0;
  display: inline-block;
  font-size: 20px;
  text-decoration: none;
  transition: all ease 0.2s;
}

.btn-black:hover,
.btn-black:active,
.btn-black:focus {
  background-color: #0cafff;
  color: #fff;
}

.btn-black:disabled {
  background-color: #000;
  color: #CCC;
}

.form-control {
  border: 1px solid #000 !important;
  border-radius: 0 !important;
  padding: 13px 15px !important;
  color: #000 !important;
  box-shadow: none !important;
}

select.form-control {
  background-image: url(../src/assets/images/arrow.png);
  background-repeat: no-repeat;
  background-position: bottom 15px right 20px;
  background-size: 28px;
}

textarea.form-control {
  height: 100px;
}

.InnerBg {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 300px;
  z-index: 0;
}

.InnerBg img {
  width: 100%;
}

.mainHome .InnerBg {
  z-index: 2;
}

.zIndex {
  position: relative;
  z-index: 1;
}

iframe {
  display: none !important;
}

.form-control::-webkit-input-placeholder {
  color: #000 !important;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #000 !important;
  opacity: 1;
}

.form-control::placeholder {
  color: #000 !important;
  opacity: 1;
}

.contact-form p {
  font-size: 12px;
  text-transform: inherit;
}

.MainSlider {
  position: relative;
  z-index: 1;
}

.feat-img video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

ul.slick-dots {
  display: flex !important;
  list-style: none;
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  margin: auto;
  justify-content: center;
  padding: 0;
}

ul.slick-dots li {
  padding: 0 5px;
}



ul.slick-dots button {
  padding: 0;
  border: 0;
  background-color: transparent;
  font-size: 0;
  width: 70px;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.56);
}

ul.slick-dots li.slick-active button {
  background-color: #0cafff;
}

.Caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100px;
  margin: auto;
  display: flex;
  justify-content: center;
  text-align: center;

}

.slick-slide {
  position: relative;
}

.Caption h2 {
  font-size: 100px;
  font-weight: bold;
  font-family: 'Tusker Grotesk 5700';
  color: #fff;
  margin: 0;
  text-transform: uppercase;
}

.App {
  position: relative;
  z-index: 0;
}

.SideIcon .App section {
  display: grid;
  place-items: center;
  position: relative;
}

.ContentSection {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  z-index: 2;

}

.HomeContentImg {
  position: relative;
  max-width: 85%;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.homelogo {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  bottom: 0;
  margin: auto;
  transition: all ease 0.2s;
}

.homelogoHover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
  margin: auto;
  visibility: hidden !important;
  transition: all ease 0.2s;
}



.homeImg {
  position: relative;
  z-index: 1;
}

.ContentSection p {
  text-transform: uppercase;
  line-height: initial;
}

.ContentSection h6 {
  color: #0cafff;
  font-size: 12px;
  font-weight: 400;
  position: relative;
  opacity: 0;
  transition: all ease 0.2s;
}


.ContentSection a {
  text-decoration: none !important;
  display: block;
}

.HomeHovers:hover .ContentSection a h6 {
  opacity: 1;
}

.HomeContentImg .homelogoHover {
  opacity: 0;
}

.HomeHovers:hover .HomeContentImg .homelogoHover {
  opacity: 1;
  visibility: visible !important;
}

.HomeHovers:hover .HomeContentImg .homelogo {
  opacity: 0;
}

.ContentSection h2 {
  font-size: 80px;
  font-weight: bold;
  font-family: 'Tusker Grotesk 5700';
  color: #000;
  margin: 0;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.App .nav {
  display: block;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 2;
  height: 100%;
  min-height: 70px;
  width: 80px;
  padding: 0;
  right: 0;
  bottom: 0;
}

.App .nav__container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
}

.App .nav__container__actions {
  padding-left: 0px;
  height: 100%;
}

.App .active {
  font-weight: bold;
}

.App ul {
  display: flex;
  font-size: 0.9rem;
  list-style: none;
  padding: 0;
  margin: 0;
  height: 100%;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  align-items: center;
  flex-direction: column;
}

.App li {
  cursor: pointer;
}

.App li:hover {
  text-decoration: underline;
}

.nav__container__actions li {
  padding: 17px 0;
}

.nav__container__actions li img {
  width: 35px;
}

.nav__container__actions li a.active .SideIcon {
  display: none;
}

.nav__container__actions li a .ActiveIcon {
  display: none;
}

.nav__container__actions li a.active .ActiveIcon {
  display: block;
}

.nav__container__actions li a.active .icon {
  display: none;
}

.whyCollab h1 {
  font-family: 'Tusker Grotesk 5700';
  color: #000;
  font-size: 90px;
}



.whyCollab p {
  font-size: 12px;
  text-transform: inherit;
}

.title {
  font-family: 'Tusker Grotesk 5700';
  font-size: 80px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 0;
}

.HomeLogosRepeat {
  background-image: url(../src/assets/images/logoMains.png);
  background-size: 100%;
  background-repeat: repeat-x;
  animation: infinite-shift-left 100000s linear;
  height: 12vw;
}

@keyframes infinite-shift-left {
  0% {
    background-position: 5000000px;
  }

  100% {
    background-position: 0;
  }
}

.SmallCaps {
  text-transform: inherit;
}

.aboutContent {
  background-image: url('../src/assets/images/aboutBg.jpg');
  background-attachment: fixed;
  background-size: cover;
  padding: 70px 0;
}

.AboutImg {
  position: relative;
}

.AboutOne {
  margin-right: -10px;
}

.AboutTwo {
  margin-left: -10px;
  position: relative;
  padding-right: 20px;
}

.AboutTwo p {
  position: absolute;
  transform: rotate(-270deg);
  color: #fff;
  top: 0;
  right: 0;
  transform-origin: center;
  margin: auto;
  text-align: center;
  bottom: 0;
  left: 0;
  font-size: 12px;
}

.AboutRights {
  padding-top: 200px;
}

.AboutOnetext {
  margin-top: 50px;
  max-width: 350px;
  color: #fff;
  font-size: 12px;
}

.ConactLink {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
  color: #0cafff !important;
}

.PressImg {
  position: relative;
}

.pressLogo {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 70%;
  margin: auto;
}

.PressPageMain a {
  text-decoration: none !important;
}

.PressContents {
  text-align: center;
  padding: 15px;
}

.PressContents h6 {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.InteriorUls {
  display: flex;
  list-style: none;
  padding-left: 0;
  margin-left: -20px;
}

.InteriorUls li {
  padding: 0 10px;
}

.InteriorUls li a {
  padding: 0 10px;
  font-size: 16px;
  color: #000;
  text-decoration: none;
}

.InteriorUls li a:hover {
  text-decoration: underline;
}

.InterHead h2 {
  text-transform: uppercase;
  font-size: 40px;
  font-weight: bold;
  font-family: 'Tusker Grotesk 5700';
  margin-bottom: 0;
  color: #000;
}

.Heading2 {
  text-transform: uppercase;
  font-size: 65px;
  font-weight: 400;
  font-family: 'CSTM Xprmntl 02';
  margin-bottom: 0;
  line-height: 0.8;
  color: #000;
}

.ProjectCount {
  font-family: 'Tusker Grotesk 5700';
  font-size: 40px;
  font-weight: bold;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 0;
}

.InteriorImagesGroup {
  display: flex;
  flex-wrap: wrap;
}

.interImgs {
  max-width: 20%;
  flex: 0 0 20%;
  padding: 1px;

}

.InerImg {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  padding-top: 73%;
}

.OverLay {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.63);
  color: #fff;
  font-size: 1.8vw;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  transition: all ease 0.2s;
  opacity: 0;
  visibility: hidden;
  font-family: 'CSTM Xprmntl 02';
}

.interImgs:hover .OverLay {
  opacity: 1;
  visibility: visible;
}

.interImgs img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.PressVideos video {
  height: 100%;
}

.DetailSlider ul.slick-dots {
  position: static;
  bottom: 0px;
  margin: auto;
}

.DetailSlider ul.slick-dots button {
  width: 6px;
  height: 6px;
  background-color: #000;
}

.DetailSlider ul.slick-dots li {
  padding: 0 10px;
}

.DetailSlider {
  position: relative;
}

.slick-arrow {
  position: absolute;

  bottom: 60px;
  width: 50px;
  height: 70px;
  border: 0px;
  font-size: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}

.slick-next {
  background-image: url('../src/assets/images/right-arrow.svg');
  right: 20px;
}

.slick-prev {
  background-image: url('../src/assets/images/left-arrow.svg');
  left: 20px;
}

.DetailVideo video {
  width: 100%;
}

.DetailVideo {
  position: relative;
}

.BtnWatch {
  position: absolute;
  left: 30px;
  top: 30px;
  width: 10vw;
}

.DetailThreeImg {
  position: relative;
  overflow: hidden;
}

.DetailThreeImg img {
  transition: all ease 0.4s;
}

.DetailThreeImg:hover img {
  transform: scale(1.05) rotate(-1deg);
}

.feat-img {
  height: 100vh;
}

.feat-img img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.HomeHovers {
  height: 100%;
}

.HomeHovers div {
  height: 100%;
}

/******** responsive css start ********/
/******** responsive css start ********/
/******** responsive css start ********/
/******** responsive css start ********/
/******** responsive css start ********/


@media (max-width:1399px) {
  .heading {
    font-size: 90px;
  }

}

@media (max-width:1199px) {
  .heading {
    font-size: 75px;
  }

}

@media (max-width:991px) {
  .ContentSection h2 {
    font-size: 60px;
  }

  .whyCollab h1 {
    font-size: 60px;
  }

  .whyCollab {
    height: auto;
  }

  .title {
    font-size: 70px;
    text-align: center;
  }

  .Caption h2 {
    font-size: 80px;
  }

}

@media (max-width:767px) {

  .HomeHovers {
    height: 100%;
  }
.ContentSection {
  top: 25px;

}.InnerBg img {
    width: 100%;
}

 .DetailSlider .feat-img img {
    height: 100vw;
}

  .HomeHovers div {
    height: auto;
  }
  .homelogo {
  bottom: 70px;
}

  .py-200 {
    padding-top: 120px;
    padding-bottom: 0px;
  }

  .ContentSection {
    position: relative;
    z-index: 0;
    padding-right: 16px
  }

  .whyCollab h1 {
    font-size: 60px;
  }

  .whyCollab {
    height: auto;
  }


  .Caption h2 {
    font-size: 60px;
    display: none;
  }

  .Caption {
    top: 120px;
  }

  .mainHome .MenuIcon img {
    filter: inherit;
  }

  ul.slick-dots button {
    width: 6px;
    height: 6px;
  }

  ul.slick-dots {
    bottom: 30px;
  }

  .HomeContentImg {
    max-width: 78%;
    margin: 1px;
    display: block;
  }

  .title {
    text-align: center;
  }

  .ContentSection h2,
  .ContentSection p {
    text-align: left;
  }

  .App ul {
    align-items: end;
    flex-direction: column;
    margin-right: 10px;
  }

  .nav__container__actions li img.SideIcon {
    width: 25px;
  }

  .ContentSection h6 {
    text-align: left;
    margin-bottom: 0px;
  }

  .heading {
    font-size: 60px;
    margin-top: 30px;
  }
	h1.heading.headingAbout.dec {
    margin-top: 55px;
}
.col-md-6.contact{
	z-index:1;
}
  .InnerBg {
    top: 10px;
    left: -10px;
    width: 220px;

  }

  .headingAbout {
    padding-left: 0;
  }

  .AboutOne {
    margin-right: 0px;
  }

  .AboutTwo {
    margin-left: 0px;
    padding-right: 0px;
  }

  .AboutRights {
    padding-top: 50px;
  }

  .AboutTwo p {
    position: static;
    transform: inherit;
  }

  .AboutOnetext {
    margin-top: 25px;
  }

  .AboutVideos video {
    height: 400px;
  }

  .PressVideos video {
    height: 100%;
  }

  .interImgs {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .InterHead h2 {
    font-size: 30px;
    text-align: left;
  }

  .ProjectCount {
    font-size: 18px;
    text-align: right !important;
    font-weight: 400;
  }

  .Heading2 {
    font-size: 50px;
  }

  .BtnWatch {
    left: 10px;
    top: 10px;
    width: 20vw;
  }

  .flex-direction-mobile {
    flex-direction: column-reverse;
    display: flex;
  }

  .HomeHovers {
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  .feat-img {
    height: 100vw;
  }



  .AboutOnetext {
    max-width: 100%;
  }



  .InteriorUls {
    margin-left: 0px;
    justify-content: center;
  }


  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    text-align: center;
  }

  .App {
    z-index: 1;
  }

  .zIndex {
    z-index: 0;
  }
}

@media (max-width:575px) {
  .InteriorUls {
    flex-wrap: wrap;
    margin-left: -10px;
  }

  .InteriorUls li {
    padding: 8px 0px;
  }

  .interImgs {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .OverLay {
    font-size: 7vw;
  }

  .InteriorUls li a {
    font-size: 15px;
  }

  .MainSlider .feat-img {
    height: auto;
  }
}

@media (max-width:400px) {
  .ContentSection h2 {
    font-size: 44px;
  }

  .AboutVideos {
    margin-top: 10px;
  }

  .whyCollab h1 {
    font-size: 37px;
    padding-top: 5px;
  }

  .whyCollab {
    height: auto;
  }

  .title {
    font-size: 50px;
  }

  .heading {
    font-size: 60px;
  }

  .InteriorUls li a {
    padding: 0 7px;
    font-size: 14px;
  }

  .MainSlider .feat-img {
    height: auto;
  }
}

@media (max-width:350px) {
  .InteriorUls li a {
    padding: 0 5px;
    font-size: 13px;
  }

  .MainSlider .feat-img {
    height: auto;
  }
}

/* csssssss*/

.PressImg:hover .PressOneImg {
  transform: scale(1.02);
}

.PressImg {
  overflow: hidden;
}

.PressImg img {
  vertical-align: middle;
  width: 100%;
  cursor: pointer;
  transition: all linear 0.2s;
}



.interImgs img {
  vertical-align: middle;
  width: 100%;
  cursor: pointer;
  transition: all linear 0.2s;
}

.DetailArrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 0;
}

.DetailArrow .arrows {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-decoration: none;
}

.DetailArrow .arrows:hover {
  color: #0cafff;
}

.DetailArrow .arrows:hover svg {
  fill: #0cafff;
}

.DetailArrow .arrows span {
  padding-right: 10px;
  padding-left: 10px;
}

.DetailArrow .arrows svg {
  width: 10px;
}

.aboutVideoDiv {
  margin-top: 0.4rem !important;
}

.bgWhite {
  background-color: #fff;
}

.container.pb-md-5.pb-4.mt-4.mt-md-5.zIndex.productsDetail p {
  text-align: center;
  margin-bottom: 8vh;
}

img.wow.fadeInDown.architecture {
  width: 70%
}

.swal2-styled.swal2-confirm {
  background-color: #0cafff !important;
}
